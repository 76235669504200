<template>
    <div id="misc">
        <div class="page-title text-center px-4">
            <div class="misc-character d-flex justify-center">
                <v-img max-width="500"
                       src="@/assets/images/logos/amex.png"></v-img>
            </div>

            <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
                <span class="me-2"> {{ $t('Page Not Found') }}</span>
            </h2>
            <p class="text-md pt-2">
                {{ $t('we could not find the page you are looking for') }}
            </p>
            <br>

            <v-btn color="primary"
                   to="/"
                   class="mb-4">
                {{ $t('Return to Home') }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'

export default {
    setup()
    {
        return {
            icons: {
                mdiAlert,
            },
        }
    },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
